<template>
  <div class="PageBox">
    <van-nav-bar
        fixed
        :border="false"
        :title="$t('resetpwd[0]')"
        left-arrow
        @click-left="$router.go(-1)"
    >
    </van-nav-bar>
    <div class="ScrollBox ">
      <van-tabs :ellipsis="false"
                color="var(--tab-line-color)"
                title-active-color="var(--tabs-title-color)"
                title-inactive-color="var(--tabs-title-inactive-color)"
                :border="false" line-width="60" v-model="r_type" @change="changeType">
        <van-tab style="padding: 0 20px;">
          <template #title>
            <div :class="{'activeTab':r_type==0}">
              <p :style="r_type==0?'color:var(--tab-line-color);font-size: 16px':'font-size: 16px'">{{$t('resetpwd2[0]')}}</p>
            </div>
          </template>
        </van-tab>
        <van-tab style="padding: 0 20px;">
          <template #title>
            <div :class="{'activeTab':r_type==1}">
              <p :style="r_type==1?'color:var(--tab-line-color);font-size: 16px':'font-size: 16px'">{{$t('resetpwd2[1]')}}</p>
            </div>
          </template>
        </van-tab>
      </van-tabs>
      <van-form @submit="onSubmit">
        <div class="resetpanel">
          <van-field v-if="r_type==0"
                     v-model.trim="postData.username"
                     :placeholder="userplaceholder"
                     clearable
                     autocomplete="off"
                     :border="false"
                     class="input"
                     type="text"
          />
          <van-field v-show="r_type==1" clearable class="input" :border="false">
            <template #input>
              <van-dropdown-menu>
                <van-dropdown-item :title="`+${postData.dest}`" ref="DropdownItem">
                  <template>
                    <ul>
                      <li v-for="item in areaList" style="color: #FFFEFF"
                          :key="item.id" :class="{on:postData.dest==item.id}"
                          @click="postData.dest=item.id,$refs.DropdownItem.toggle()">
                        <span>+{{item.id}}&#12288;{{item.name}}</span></li>
                    </ul>
                  </template>
                </van-dropdown-item>
              </van-dropdown-menu>
              <input type="tel" v-model.trim="postData.username" :placeholder="$t('register2[2]')" style="border: 0; flex: 1; width: 100px; background: transparent"
              />
            </template>
          </van-field>
          <van-field
              type="password"
              autocomplete="off"
              style="height: 0; width: 0; padding: 0; position: absolute"
          />
          <van-field
              v-model.trim="postData.smscode"
              autocomplete="off"
              type="digit"
              class="input"
              :placeholder="$t('resetpwd[2]')"
              clearable
              :border="false"
          >
            <template #button>
              <van-button class="van-button-s" style="margin-right:-30px;width: 100px;font-size: 14px"

                          native-type="button"
                          @click="getSmsCode"
                          :loading="isSendCode"
                          :loading-text="$t('resetpwd[3]')"
              >
                <span>{{ $t('resetpwd[3]') }}</span></van-button
              >
            </template>
          </van-field>
          <van-field
              type="password"
              v-model.trim="postData.password"
              :placeholder="$t('resetpwd[4]')"
              clearable
              autocomplete="off"
              :border="false"
              class="input"
              :type="showPass1 ? 'text' : 'password'"
              :right-icon="showPass1 ? 'eye' : 'closed-eye'"
              @click-right-icon="showPass1 = !showPass1"
          />
          <!--        <van-field-->
          <!--                v-model.trim="postData.re_password"-->
          <!--                :placeholder="$t('register.placeholder[3]')"-->
          <!--                clearable-->
          <!--                autocomplete="off"-->
          <!--                :border="false"-->
          <!--                class="input"-->
          <!--                :type="showPass1 ? 'text' : 'password'"-->
          <!--                :right-icon="showPass1 ? 'eye' : 'closed-eye'"-->
          <!--                @click-right-icon="showPass1 = !showPass1"-->
          <!--        />-->
          <Loading />
          <div class="centerPanel" style="padding: 33px 16px">
            <van-button @click="onSubmit()">{{ $t('resetpwd[5]') }}</van-button>
          </div>
        </div>
      </van-form>
    </div>

  </div>
</template>

<script>
  import $Dialog from "../common/Dialog";
  import crypto  from '@/common/AES.js'

  export default {
    name: 'ResetPwd',
    components: {

    },
    props: [],
    data() {
      return {
        isSendCode:false,
        r_type:0,
        userplaceholder:'',
        countdown:120,
        areaList: [
          {
            id: "84",
            name: "Thailand(ประเทศไทย)",
          },
        ],
        postData: {
          dest: 84,
          username: "",
          showPass1: false,
          password: "",
          re_password: "",
          smscode: "",
          code: "",
          verify_data: "",
          code_rand: "",
          ivddd:'',
          fp: "dft",
          submit_key: "",
          rkey: null,
          uniqueID: localStorage["phoneId"] || "", //手机唯一标识
        },
      }
    },
    computed: {

    },
    watch: {

    },
    created() {
      this.showPass1 = false;
      this.userplaceholder = this.$t('register.placeholder[0]')
      // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
      // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
    },
    mounted() {
      this.sendText = this.$t('register.text[1]');
      this.$Model.SmsCode((data) => {
        if (data!=-1){
          this.areaList = data
          this.postData.dest = "84"
        }
      })
    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      changeType(r_type) {
        this.r_type = r_type;
        if (r_type==0){
          this.userplaceholder = this.$t('register.placeholder[0]');
        }else{
          this.userplaceholder = this.$t('register2[2]')
        }
      },
      onSubmit() {
        this.$Model.ResetPwd(this.postData, (data) => {
          var msg = this.$t("common3[0]");
          if (data.code == 0) {
            msg = data.code_dec;
          }
          $Dialog.Toast(msg);
          if (data.code == 1){
            this.$router.replace("/login?username="+this.postData.username);
          }
        });
      },
      getSmsCode() {
        let email = this.postData.username;
        var reg = /^[0-9]+$/;
        if (!email) {
          this.$Dialog.Toast(this.$t("register.placeholder[0]"));
          return;
        }else if ((this.r_type==0)&&(email.split("@").length==1 || email.split(".").length==1)) {
          this.$Dialog.Toast(this.$t("register.placeholder[7]"));
          return;
        }else if (this.r_type==1&&!reg.test(email)) {
          this.$Dialog.Toast(this.$t("register2[2]"));
          return;
        }
        this.postData.re_password = this.postData.password;
        if (this.postData.password != this.postData.re_password) {
          this.$Dialog.Toast(this.$t("register.placeholder[5]"));
          return;
        }
        this.isSendCode = true;
        this.isSendCode = true;
        this.$Model.GetRegCode(
            {
              reg_type:this.reg_type,
              mail: this.postData.username,
              code: this.postData.code,
              dest: this.postData.dest,
            },
            (data) => {
              if (data.code == 0) {
                $Dialog.Toast(data.code_dec);
              }
            }
        );
      },
      requesCode(){
        this.$Model.GetRegCode(
            {
              reg_type:this.r_type,
              mail: this.postData.username,
              code: this.postData.code,
              dest: this.postData.dest,
            },
            (data) => {
              if (data.code == 0) {
                $Dialog.Toast(data.code_dec);
              }
            }
        );
      },
      settime() {
        var that = this;
        console.log("timer"+that.countdown);
        if (that.countdown == 0) {
          that.sendText = that.$t('register.text[1]');
          that.countdown = 120;
          that.isSendCode = false;
          clearTimeout(that.timer)
          return;
        } else {
          that.sendText = "("+that.countdown+")";
          console.log("set text:"+that.sendText);
          that.countdown--;
        }
        that.timer = setTimeout(function() {
          that.settime()
        },1000)
      },
    }
  }
</script>
<style scoped>
.PageBox {
  padding: 0;
  background-color: var(--main-bg-color);
  min-height: 900px;
}
.van-nav-bar {

}
.topDiv{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 450px;
  background-color: var(--main-bg-color);
}

.ScrollBox >>> .van-tab {
  height: 35px;
  /*background: #eff0f2;*/
  padding: 0;
}

.ScrollBox {
  margin-top: 40px;
  width: 100%;
  margin-left: 5px;
}

.van-cell>>>.van-cell__title{
  font-size: 14px;
}

.resetpanel {
  margin: 15px;
  margin-top: 35px;
  border-radius: 10px;
  background-color: transparent;
  background-size: cover;
  /*background-color: #191C23;*/
  padding-top:10px;
}

.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
  margin: 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}
.van-cell--borderless.input {
  width: 85%;
  border-radius: 30px;
  overflow: hidden;
  margin-top: 15px;
  margin-left: 30px;
  padding: 0 30px 0 13px;
  height: 45px;
}

.van-cell--borderless >>> .van-icon {
  font-size: 25px !important;
  color: #ccc !important;
  margin-top: 8px;
}

.van-cell--borderless.input >>> .van-field__control {
  padding-left: 7px;
  height: 100%;
  font-size: 16px;
}

.van-cell--borderless.input >>> .van-field__control::-webkit-input-placeholder {
  color: var(--cell_placeholder);
  font-size: 16px;
  font-family: Alibaba;
}

.van-cell--borderless.input >>> .van-field__control::-moz-placeholder {
  color:var(--cell_placeholder);
  font-size: 16px;
  font-family: Alibaba;
}

.van-cell--borderless.input >>> .van-field__control::-ms-input-placeholder {
  color: var(--cell_placeholder);
  font-size: 16px;
  font-family: Alibaba;
}

.van-cell--borderless >>> .van-field__right-icon .van-icon-closed-eye {
  font-size: 21px !important;
  color: var(--cell_placeholder) !important;
  margin-right:-10px;
}

.van-field__right-icon >>> van-icon-closed-eye {
  font-size: 18px !important;
  color: var(--cell_placeholder) !important;
  margin-right:-10px;
}

.van-field__right-icon >>> .van-icon-eye{
  font-size: 18px !important;
  color:var(--cell_placeholder) !important;
  margin-right:-10px;
}


.van-cell--borderless >>> .van-field__control {
  padding-left: 4px;
}
.van-cell >>> .van-field__control::-webkit-input-placeholder,
.van-cell >>> .van-field__control > input::-webkit-input-placeholder {
  color: var(--cell_placeholder);
  font-size: 16px;
  font-family: Alibaba;
}

.van-cell >>> .van-field__control::-moz-placeholder,
.van-cell >>> .van-field__control > input::-moz-placeholder {
  color: var(--cell_placeholder);
  font-size: 16px;
  font-family: Alibaba;
}

.van-cell >>> .van-field__control:-ms-input-placeholder,
.van-cell >>> .van-field__control > input::-ms-input-placeholder {
  color: var(--cell_placeholder);
  font-size: 16px;
  font-family: Alibaba;
}

/* .van-cell>>>.van-dropdown-menu::-webkit-input-placeholder{
        color: #777;
        font-size: 19px;
        font-family: Alibaba;
    } */
.van-cell >>> .van-cell__value,
.van-cell >>> .van-field__body,
.van-cell >>> .van-field__control,
.van-cell >>> .van-dropdown-menu,
.van-cell >>> input {
  height: 100%;
}

.btn {
  width: 95%;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: var(--button_bg);
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 15px 10px 30px;
}
</style>
